import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"

const PageBlock = styled.div`
  padding: 8px 16px;
  display: inline-block;
  margin: 5px;
  background: ${props => props.state === 'active' ? '#57b557' : '#f5f5f5'};
`

const Pagination = ({pages, cur}) => {
  const pageBlocks = [];
  for (let i = 1; i<pages; i++) {
    if (i===cur) {
      pageBlocks.push(
        <PageBlock state="active" key={i}>
          <span>{i}</span>
        </PageBlock>
      );
      continue;
    }
    pageBlocks.push(
      <Link
        key={i}
        to={`/p/${i}`}
        style={{
          textDecoration: 'none'
        }}
        >
        <PageBlock>  
          {i}
        </PageBlock>
      </Link>
    )
  }
  return (
    <div>
      {pageBlocks}
    </div>
  )
}

export default Pagination
